import { Icon } from '@iconify/react';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import contentModified from '@iconify-icons/eos-icons/content-modified';
import bxCopyAlt from '@iconify-icons/bx/bx-copy-alt';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import settingsOutline from '@iconify/icons-eva/settings-outline';
import Iconify from '../../Iconify';
import ConfirmationModal from '../../ConfirmationModal';
import MenuPopover from '../../MenuPopover';
import { DELETE } from '../../../constants/labels';

MoreMenu.propTypes = {
  handleDelete: PropTypes.func,
  id: PropTypes.string,
  item: PropTypes.object,
  nameInEnglish: PropTypes.string,
  nameInSpanish: PropTypes.string,
  isMasculine: PropTypes.bool,
  handleEditItem: PropTypes.func,
  handleDuplicateItem: PropTypes.func,
  validateRows: PropTypes.func,
  needSettings: PropTypes.bool,
  dontNeedDuplicateButton: PropTypes.bool,
};

export default function MoreMenu({
  id,
  item,
  handleDelete,
  nameInEnglish,
  nameInSpanish,
  isMasculine,
  handleEditItem,
  handleDuplicateItem,
  validateRows,
  needSettings,
  dontNeedDuplicateButton,
}) {
  const history = useHistory();

  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const removeItem = () => {
    setOpenModal(!openModal);
    if (validateRows) validateRows();
    handleDelete(id);
  };

  const editItem = () => {
    if (handleEditItem) {
      handleEditItem(item, id);
    } else {
      history.push(`/dashboard/${nameInEnglish}/${id}/edit`);
    }
  };

  const duplicateItem = () => {
    if (handleDuplicateItem) {
      handleDuplicateItem(item, id);
    } else {
      history.push(`/dashboard/${nameInEnglish}/${id}/duplicate`);
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="center-top"
        sx={{
          mt: -1,
          width: 'auto',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem onClick={editItem}>
          <IconButton>
            <Icon icon={needSettings ? settingsOutline : contentModified} />
          </IconButton>
        </MenuItem>
        {!dontNeedDuplicateButton && (
          <MenuItem onClick={duplicateItem}>
            <IconButton>
              <Icon icon={bxCopyAlt} />
            </IconButton>
          </MenuItem>
        )}
        <MenuItem onClick={() => setOpenModal(!openModal)}>
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </MenuItem>
      </MenuPopover>
      <ConfirmationModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        onConfirm={removeItem}
        title={DELETE(nameInSpanish, isMasculine).title}
        subtitle={DELETE(nameInSpanish, isMasculine).message}
      />
    </>
  );
}
