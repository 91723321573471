// material

import { Box , useTheme} from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ ...other }) {
  const theme = useTheme();
  return (
    <Box
    component="img"
    alt="logo"
    src={theme.palette.mode === 'dark' ? '/static/brand/hola2.png' : '/static/brand/hola.png'}
    height={65}
    marginLeft={3}
    marginTop={5}
    {...other}
  />
  );
}
