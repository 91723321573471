import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { useSnackbar } from 'notistack';
// components
import { useHistory } from 'react-router';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  ListItem,
  Grid,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar
} from '@material-ui/core';
import { setCurrentCompany, setCurrentBranch } from '../../redux/slices/company';
import { deleteCompany, getCompaniesByUser, getCompany } from '../../services/CompanyService';
import TableSubItemsList from '../../components/ui/Table/TableSubItemsList2';
// import { resetCart } from '../../redux/slices/product';
import NewCompanyModal from '../../components/company-selection/NewCompanyModal';
import InvitationTable from '../../components/ui/Table/InvitationTable';
import { deleteUserInCompany, editStatusFromUser } from '../../services/UserService';
import validateCompanySubscribed from '../../utils/validatePayments';
import validateUserHasPermission from '../../utils/validateUserHasPermission';

const TABLE_HEAD = [
  { id: 'companyName', label: 'Nombre de la organización', alignRight: false },
  { id: '', label: 'Acciones', alignRight: false },
];

const MyOrganizations = () => {
  const history = useHistory();
  const { myProfile, userProfile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
console.log('myProfile',myProfile)
  const { currentBranch, currentCompany } = useSelector((state) => state.company);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openBranchModal, setOpenBranchModal] = useState(false);
  // const [selectedItem, setSelectedItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [companyTemp, setCompanyTemp] = useState({});

  const handleSelectedBranch = async (branchSelected) => {
    if (currentBranch.id !== branchSelected.id) {
    // dispatch(resetCart());      
      console.log('response1.status', setCurrentBranch);
      dispatch(
        setCurrentCompany({
          id: companyTemp.id,
          name: companyTemp.companyName,
          photoURLCover: companyTemp.photoURLCover,
          photoURL: companyTemp.photoURL,
          subscription: companyTemp?.subscription,
          rootUser: companyTemp.rootUser,
        })
      );
      console.log('response3.status', setCurrentCompany);
      dispatch(
        setCurrentBranch({
          id: branchSelected.id,
          name: branchSelected.name,
          photoURLCover: branchSelected.photoURLCover,
          photoURL: branchSelected.photoURL,
          type: branchSelected.type,
        })
        
      );
   
      // setSelectedCompany(currentBranch); // borar talvez
      // setSelectedCompany(companySelected);
      
      enqueueSnackbar(`Sucursal: ${branchSelected.name} ${'Seleccionada'}`, {
        variant: 'success',
        
      });
      const isOwner = currentCompany.rootUser === myProfile.id;

      if (isSuperAdmin) {
        history.push('/dashboard/Contactos');
      } else {
        history.push('/dashboard/Menu');
      }
    

      // console.log('companyTemp', companyTemp);
      const validation = await validateCompanySubscribed(companyTemp?.subscription, companyTemp, userProfile);
      if (!validation) {
        history.push(`/dashboard/payment`);
      }
    }
  };

  const handleSelectedCompany = (companySelected) => {
    setCompanyTemp(companySelected);
    setSelectedCompany(companySelected); // borrar talvez
    console.log('response2.status', companySelected);
  
    /* if (currentCompany.id !== companySelected.id) {
      dispatch(setCurrentBranch(''));
      dispatch(
        setCurrentCompany({
          id: companySelected.id,
          name: companySelected.companyName,
          photoURLCover: companySelected.photoURLCover,
          photoURL: companySelected.photoURL,
        })
      );
      // localStorage.setItem('companyId', companySelected.id);
      setSelectedCompany(companySelected);

      enqueueSnackbar(`Compañía: ${companySelected.companyName} ${'Seleccionada'}`, {
        variant: 'success',
      }); 
    } */
  };

  // eslint-disable-next-line no-unused-vars
  const handleCreateBranch = (item) => {
    // setSelectedItem(item);
    setOpenBranchModal(true);
  };

  const handleDelete = async (id) => {
    const flag = await validateUserHasPermission(myProfile, id, 'delete-company');
    if (!flag) {
      enqueueSnackbar(`No tiene permiso para eliminar esta compañía`, {
        variant: 'error',
      });
      return;
    }
    const company = await getCompany(id);
    await deleteCompany(company, id);

    if (currentCompany.id === id) {
      dispatch(setCurrentCompany({}));
      dispatch(setCurrentBranch({}));
    }
    const newList = companies.filter((el) => el.id !== id);
    setCompanies(newList);
  };

  // const findCompanyById = (id) => {
  //   return companies.filter((el) => el.id === id)[0];
  // };
  const isSuperAdmin = myProfile?.isSuperAdmin || false; // Asegúrate de manejar el caso en que myProfile sea nulo
  const onBranchCreated = async (_, branch) => {
    dispatch(
      setCurrentCompany({
        id: companyTemp.id,
        name: companyTemp.companyName,
        photoURLCover: companyTemp.photoURLCover,
        photoURL: companyTemp.photoURL,
        subscription: companyTemp?.subscription,
        rootUser: companyTemp.rootUser,
      })
    );
    dispatch(setCurrentBranch(branch));
    // agregar validación de pago
    getCompanies();
    /* const companyId = branch.parentCompanyId;
    const myCompany = findCompanyById(companyId); // companies[companyId];
    const branches = [...myCompany.branches,  { name: branch.name }];
    const companyEdited = { ...myCompany, branches };
    const newList = [...companies, { [companyId]: companyEdited }];
    setCompanies(newList); */
  };

  const handleEditItem = (item, id) => {
    if (currentCompany.id !== id) {
      dispatch(
        setCurrentCompany({
          id: item.id,
          name: item.companyName,
          photoURLCover: item.photoURLCover,
          photoURL: item.photoURL,
          subscription: companyTemp?.subscription,
          rootUser: companyTemp.rootUser,
        })
      );
      dispatch(setCurrentBranch({}));
    }

    history.push(`/dashboard/organization/${id}/settings`);
  };

  const getCompanies = useCallback(async () => {
    try {
      const companies = await getCompaniesByUser(myProfile, ['owner', 'aceptado']);
      setCompanies(companies);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>

      <TableSubItemsList
        TABLE_HEAD={TABLE_HEAD}
        getDocuments={getCompanies}
        handleDelete={handleDelete}
        nameInSpanish="Organización"
        nameInEnglish="organization"
        isMasculine={Boolean(false)}
        needMassiveCharge={Boolean(false)}
        isLoading={isLoading}
        documents={companies}
        applySortFilter={applySortFilter}
        isList="branches"
        handleCreateSubItem={handleCreateBranch}
        handleClickItem={handleSelectedCompany}
        handleClickSubItem={handleSelectedBranch}
        handleEditItem={handleEditItem}
        dontNeedCheckBox={Boolean(true)}
        needSettings={Boolean(true)}
      />

      {openBranchModal && (
        <NewCompanyModal
          success={success}
          setSuccess={setSuccess}
          company={{}}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setOpenModal={setOpenBranchModal}
          openModal={openBranchModal}
          type="branch"
          parentCompany={selectedCompany}
          onBranchCreated={onBranchCreated}
        />
      )}
    </>
  );
};

MyOrganizations.propTypes = {};

export default MyOrganizations;

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.companyName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
