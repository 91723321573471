import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
// import searchFill from '@iconify/icons-eva/search-fill';
// import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// import roundFilterList from '@iconify/icons-ic/round-filter-list';
import contentModified from '@iconify-icons/eos-icons/content-modified';
// import bxCopyAlt from '@iconify-icons/bx/bx-copy-alt';
import { useState } from 'react';

// material
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Button, Toolbar, Tooltip, IconButton, Typography, InputAdornment, OutlinedInput } from '@material-ui/core';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import plusFill from '@iconify/icons-eva/plus-fill';
import bxCopyAlt from '@iconify-icons/bx/bx-copy-alt';
import { DELETE } from '../../../constants/labels';
import Iconify from '../../Iconify';
import ConfirmationModal from '../../ConfirmationModal';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ToolbarComponent.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  id: PropTypes.string,
  handleDelete: PropTypes.func,
  handleEditItem: PropTypes.func,
  handleDuplicateItem: PropTypes.func,
  setSelected: PropTypes.func,
  name: PropTypes.string,
  isMasculine: PropTypes.bool,
  nameInEnglish: PropTypes.string,
  needMassiveCharge: PropTypes.bool,
  urlToDownloadSheet: PropTypes.string,
  dontNeedCreateButton: PropTypes.bool,
  dontNeedDuplicateButton: PropTypes.bool,
  handleCreateItem: PropTypes.func,
  anotherButton: PropTypes.func,
  anotherTextButton: PropTypes.string,
};

export default function ToolbarComponent({
  numSelected,
  filterName,
  onFilterName,
  id,
  handleDelete,
  handleEditItem,
  handleDuplicateItem,
  setSelected,
  name,
  isMasculine,
  nameInEnglish,
  needMassiveCharge,
  urlToDownloadSheet,
  dontNeedCreateButton,
  dontNeedDuplicateButton,
  handleCreateItem,
  anotherButton,
  anotherTextButton,
}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cargaMasiva = () => {
    history.push(`/dashboard/${nameInEnglish}/massiveUpload`);
  };

  const removeItem = () => {
    setOpenModal(!openModal);
    setSelected([]);
    handleDelete(id);
  };

  const createItem = () => {
    if (handleCreateItem) {
      handleCreateItem();
    } else {
      history.push(`/dashboard/${nameInEnglish}/create`);
    }
  };

  const editItem = () => {
    if (handleEditItem) {
      handleEditItem(id);
    } else {
      history.push(`/dashboard/${nameInEnglish}/${id}/edit`);
    }
  };

  const duplicateItem = () => {
    if (handleDuplicateItem) {
      handleDuplicateItem(id);
    } else {
      history.push(`/dashboard/${nameInEnglish}/${id}/duplicate`);
    }
  };

  return (
    <RootStyle
    
    >
     

      {numSelected > 0 ? (
        <>
          {id !== null && (
            <Tooltip title="Modify">
              <IconButton onClick={editItem}>
                <Icon icon={contentModified} />
              </IconButton>
            </Tooltip>
          )}
          {id !== null && !dontNeedDuplicateButton && (
            <Tooltip title="Clone">
              <IconButton onClick={duplicateItem}>
                <Icon icon={bxCopyAlt} />
              </IconButton>
            </Tooltip>
          )}
          {id !== null && (
            <Tooltip title="Delete">
              <IconButton onClick={() => setOpenModal(!openModal)}>
                <Iconify icon={'eva:trash-2-fill'} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <>
          {anotherButton && (
            <Button variant="contained" onClick={anotherButton}>
              {anotherTextButton}
            </Button>
          )}
       
          {needMassiveCharge && (
            <>
              <Button
                id="basic-button"
                variant="outlined"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                +
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <a href={urlToDownloadSheet} style={{ textDecoration: 'none', color: 'black', fontWeight: 'normal' }}>
                    Descargar plantilla
                  </a>
                </MenuItem>
                <MenuItem onClick={cargaMasiva}>Carga masiva</MenuItem>
              </Menu>
            </>
          )}
        </>
      )}
      <ConfirmationModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        onConfirm={removeItem}
        title={DELETE(name, isMasculine).title}
        subtitle={DELETE(name, isMasculine).message}
      />
    </RootStyle>
  );
}
