import faker from 'faker';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { set, sub, formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';

import clockFill from '@iconify/icons-eva/clock-fill';
import personFill from '@iconify/icons-eva/person-fill';

// material
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  ListItem,
  Grid,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  useTheme,
} from '@material-ui/core';
// utils
import { mockImgAvatar } from '../../utils/mockImages';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import OtherOrganizations from './MyOrganizations2';

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      Organizaciones
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title,
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItem
      button
      to="#"
      disableGutters
      key={notification.id}
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        '&:not(:last-of-type)': { mb: '1px' },
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {formatDistanceToNow(new Date(notification.createdAt))}
          </Typography>
        }
      />
    </ListItem>
  );
}

export default function NotificationsPopover2() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [pendingCompanies, setPendingCompanies] = useState([]);
  const [notifications, setNotifications] = useState();
  const theme = useTheme();

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  return (
    <>
      <MIconButton ref={anchorRef} onClick={() => setOpen(true)} color={open ? 'primary' : 'default'}>
        <Badge color="error">
          <Icon icon="ic:baseline-diversity-3" />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        sx={{ width: 360, maxHeight: 800, overflowY: 'auto' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 13.7 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
              }}
              variant="subtitle1"
            >
              Organizaciónes
            </Typography>
          </Box>
        </Box>

        <Divider />

        <OtherOrganizations
          sx={{ width: 60, height: 60 }}
          pendingCompanies={pendingCompanies}
          setPendingCompanies={setPendingCompanies}
        />
      </MenuPopover>
    </>
  );
}
