import faker from 'faker';

import PropTypes from 'prop-types';

import { noCase } from 'change-case';

import { useRef, useState, useEffect, useCallback } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { set, sub, formatDistanceToNow } from 'date-fns';

import { Icon } from '@iconify/react';

import bellFill from '@iconify/icons-eva/bell-fill';

import { useSnackbar } from 'notistack';

import clockFill from '@iconify/icons-eva/clock-fill';

import doneAllFill from '@iconify/icons-eva/done-all-fill';

// material

import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  Snackbar,
  ListSubheader,
  ListItemAvatar,
} from '@material-ui/core';

// utils

import { useDispatch, useSelector } from 'react-redux';
import { mockImgAvatar } from '../../utils/mockImages';

// components

import Scrollbar from '../../components/Scrollbar';

import MenuPopover from '../../components/MenuPopover';

import { getCompanyById } from '../../_apis_/companies';
import { MIconButton } from '../../components/@material-extend';
import { getNodoByCompany, getNodoById, softDeleteNodo } from '../../_apis_/nodo';
import OtherOrganizations from './OtherOrganizations';

import notificationSound from '../../assets/noti.mp3';
import {
  getDevicesByCompany,
  getDevicesByCompanysql,
  getDevicesMeasuremntesByDatesqlvistas,
  getDevicesMeasuremntesByDatevistas,
} from '../../_apis_/multiapiData';
// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),

    title: 'Your order is placed',

    description: 'waiting for shipping',

    avatar: null,

    type: 'order_placed',

    createdAt: set(new Date(), { hours: 10, minutes: 30 }),

    isUnRead: true,
  },

  {
    id: faker.datatype.uuid(),

    title: faker.name.findName(),

    description: 'answered to your comment on the Minimal',

    avatar: mockImgAvatar(2),

    type: 'friend_interactive',

    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),

    isUnRead: true,
  },

  {
    id: faker.datatype.uuid(),

    title: 'You have new message',

    description: '5 unread messages',

    avatar: null,

    type: 'chat_message',

    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),

    isUnRead: false,
  },
];

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}

      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,

      title,
    };
  }

  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,

      title,
    };
  }

  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,

      title,
    };
  }

  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,

      title,
    };
  }

  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,

    title,
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItem
      button
      to="#"
      disableGutters
      key={notification.id}
      component={RouterLink}
      sx={{
        py: 1.5,

        px: 2.5,

        '&:not(:last-of-type)': { mb: '1px' },

        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,

              display: 'flex',

              alignItems: 'center',

              color: 'text.disabled',
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />

            {formatDistanceToNow(new Date(notification.createdAt))}
          </Typography>
        }
      />
    </ListItem>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);

  const [pendingCompanies, setPendingCompanies] = useState([]);

  const [soundPlayed, setSoundPlayed] = useState(false); // New state to tra

  const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const [totalAlarmCount, setTotalAlarmCount] = useState(0);
  const totalUnRead = notifications.filter((item) => pendingCompanies.includes(item)).length + totalAlarmCount;
  const [isLoading, setIsLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [showNotificationMessage, setShowNotificationMessage] = useState(0);
  const { currentBranch, currentCompany } = useSelector((state) => state.company);
  const [filteredDevices, setFilteredDevices] = useState([]);

  console.log('total', totalAlarmCount);

  const notificationAudioRef = useRef(null);

  async function reemplazarNombreVariable(variable) {
    const nuevoNombre = variable.replace(/^site(\d+)$/i, 'data$1');
    return nuevoNombre;
  }

  const formatMeasurement = (measurement, unit, multiplier) => {
    if (measurement === null || measurement === undefined) {
      return null; // Retorna null si no hay medición.
    }

    let value = measurement;

    // Aplica el multiplicador si está disponible
    if (multiplier) {
      value *= multiplier;
    }

    // Formatea el valor con dos decimales
    const formattedValue = value.toFixed(2);

    // Agrega la unidad si está disponible
    return unit ? `${formattedValue} ${unit}` : formattedValue;
  };

  useEffect(() => {
    const getInformation = async () => {
      setIsLoading(true);
      try {
        const company = await getCompanyById(currentCompany.id);

        // Obtener dispositivos simultáneamente
        const [data, data2] = await Promise.all([getDevicesByCompany(company), getDevicesByCompanysql(company)]);

        // Filtrar y mapear datos combinados
        const combinedData = data.concat(data2).filter((device) => device.status === 'activo');
        const resulDevice = combinedData.map((el) => ({
          ...el,
          organizations: (el.companies || []).map((org) => org.companyName).join(', '),
          zones: (el.zones || []).map((zone) => zone.nombre).join(', '),
        }));

        // Función para obtener mediciones agrupadas
        const getGroupedMeasurements = async (el, type) => {
          const params =
            type === 'KOLIBRI'
              ? [el.temperatura, el.tempbaro, el.presion, el.nivel]
              : [el.temperatura, el.tempbaro, el.presion, el.nivel, el.caudal, el.fuga];
        };

        // Función auxiliar para formatear mediciones de manera segura
        const safeFormatMeasurement = (measurement, unit, multi) => {
          if (measurement == null) return null;
          return formatMeasurement(measurement, unit, multi);
        };

        // Crear promesas para obtener mediciones
        const promises = resulDevice.map(async (el) => {
          const [measurementsKOLIBRI, measurementsSQL] = await Promise.all([
            getGroupedMeasurements(el, 'KOLIBRI'),
            getGroupedMeasurements(el, 'SQL'),
          ]);

          const measurementDefinitions = el.measurementDefinitions || [];
          const getUnitAndMulti = (id) => {
            const def = measurementDefinitions.find((d) => d.id === id) || {};
            return { unidad: def.unidad, multi: def.multi };
          };

          const formattedData = {
            tempe: safeFormatMeasurement(
              measurementsKOLIBRI?.[el.temperatura],
              ...Object.values(getUnitAndMulti(el.temperatura))
            ),
            temp: safeFormatMeasurement(
              measurementsKOLIBRI?.[el.tempbaro] || measurementsSQL?.[el.tempbaro],
              ...Object.values(getUnitAndMulti(el.tempbaro))
            ),
            pre: safeFormatMeasurement(
              measurementsKOLIBRI?.[el.presion] || measurementsSQL?.[el.presion],
              ...Object.values(getUnitAndMulti(el.presion))
            ),
            niv: safeFormatMeasurement(measurementsKOLIBRI?.[el.nivel], ...Object.values(getUnitAndMulti(el.nivel))),
            cau: safeFormatMeasurement(measurementsSQL?.[el.caudal], ...Object.values(getUnitAndMulti(el.caudal))),
            fu: safeFormatMeasurement(measurementsSQL?.[el.fuga], null, getUnitAndMulti(el.fuga).multi),
          };

          const filteredData = Object.fromEntries(
            Object.entries(formattedData).filter(
              ([_, value]) => value != null && value.trim() !== '0' && parseFloat(value) !== 0
            )
          );

          return { ...el, ...filteredData };
        });

        const newDevices = await Promise.all(promises);
        setDevices(newDevices);
      } catch (error) {
        console.error('Error fetching information:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // Espera de 5 segundos antes de ejecutar la función
    const timer = setTimeout(() => {
      getInformation();
    }, 5000);

    // Limpiar el temporizador si el componente se desmonta
    return () => clearTimeout(timer);
  }, [currentCompany.id]);

  useEffect(() => {
    setFilteredDevices(devices);
  }, [devices]);

  const voltageAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo')
    .filter((device) => {
      // Convertir voltaje a número para comparación
      const voltage = Number(device.batteryInfoVoltageInVolt);

      if ((device.marca === 'Keller' && voltage < 3.4) || (device.marca === 'HWM' && voltage < 6)) {
        // Alarma para Keller si el voltaje es menor a 3.4
        // Alarma para HWM si el voltaje es menor a 6
        device.voltageAlarm = true;
        return true;
      }

      device.voltageAlarm = false;
      return false;
    });

  // Contar dispositivos con alarma por voltaje

  const currentTime = new Date();

  // Definir el límite de tiempo para la alarma (24 horas en milisegundos)
  const alarmTimeLimit = 24 * 60 * 60 * 1000;
  // Puedes usar voltageAlarmCount como desees en tu aplicación.
  const communicationAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo')
    .filter((device) => {
      const lastTransmissionDateTime = new Date(device.lastMeasurementTransmissionDateTimeUtc);

      // Calcular la diferencia de tiempo entre la última transmisión y la hora actual
      const timeDifference = currentTime - lastTransmissionDateTime;

      // Verificar si la diferencia de tiempo supera el límite establecido
      if (timeDifference > alarmTimeLimit) {
        // Puedes hacer algo aquí para indicar la alarma, por ejemplo, cambiar el color
        // de la barra en el gráfico.
        device.communicationAlarm = true; // Añadir una propiedad communicationAlarm para contar después.
        return true; // Este dispositivo cumple con la condición de alarma.
        // eslint-disable-next-line no-else-return
      } else {
        device.communicationAlarm = false;
        return false; // Este dispositivo no cumple con la condición de alarma.
      }
    });

  // Filtrar dispositivos y verificar la condición de alarma de batería
  const batteryAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo' && device.marca === 'Keller')
    .filter((device) => {
      // Convertir el porcentaje de capacidad a número para comparación
      const batteryCapacityPercent = Number(device.batteryInfoCapacityInPercent);

      if (batteryCapacityPercent < 70) {
        // Alarma si el porcentaje de capacidad es menor a 70
        device.batteryAlarm = true;
        return true;
      }

      device.batteryAlarm = false;
      return false;
    });

  // Contar dispositivos con alarma de batería
  const batteryAlarmCount = batteryAlarmDevices.length;

  // Puedes usar batteryAlarmCount como desees en tu aplicación.
  // Filtrar dispositivos y verificar la condición de alarma por señal
  const signalAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo')
    .filter((device) => {
      // Convertir la señal a número para comparación
      const signalQuality = Number(device.signalQuality);

      if (signalQuality < 10) {
        // Alarma si la señal es menor a 10
        device.signalAlarm = true;
        return true;
      }

      device.signalAlarm = false;
      return false;
    });

  // Contar dispositivos con alarma por señal

  const temperatureAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo')
    .filter((device) => {
      const lastTransmissionDateTime = new Date(device.lastMeasurementTransmissionDateTimeUtc);

      // Calcular la diferencia de tiempo entre la última transmisión y la hora actual
      const timeDifference = currentTime - lastTransmissionDateTime;
      const temperaturaNumerica = parseFloat(device.tempe);
      // Verificar si la diferencia de tiempo supera el límite establecido
      if (device.marca === 'HWM' && temperaturaNumerica > 35.0) {
        // Puedes hacer algo aquí para indicar la alarma, por ejemplo, cambiar el color
        // de la barra en el gráfico.
        device.temperaturenAlarm = true; // Añadir una propiedad communicationAlarm para contar después.
        return true; // Este dispositivo cumple con la condición de alarma.
        // eslint-disable-next-line no-else-return
      } else {
        device.temperatureAlarm = false;
        return false; // Este dispositivo no cumple con la condición de alarma.
      }
    });

  // Puedes usar voltageAlarmCount como desees en tu aplicación.

  const humidityAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo' && device.marca === 'Keller')
    .filter((device) => {
      // Convertir la humedad a número para comparación
      const humidityValue = Number(device.humidity);

      if (humidityValue > 90) {
        // Alarma si la humedad es mayor a 90
        device.humidityAlarm = true; // Añadir una propiedad humidityAlarm para contar después.
        return true;
      }

      device.humidityAlarm = false;
      return false;
    });

  // Puedes usar voltageAlarmCount como desees en tu aplicación.

  useEffect(() => {
    // Calcula la cantidad de invitaciones pendientes

    // Calcula la cantidad de alarmas únicas
    const filterDevicesWithValidacion = (devices) => devices.filter((device) => device.validacion2 === false);

    const filteredCommunicationAlarmDevices = filterDevicesWithValidacion(communicationAlarmDevices);
    const filteredSignalAlarmDevices = filterDevicesWithValidacion(signalAlarmDevices);
    const filteredBatteryAlarmDevices = filterDevicesWithValidacion(batteryAlarmDevices);
    const filteredVoltageAlarmDevices = filterDevicesWithValidacion(voltageAlarmDevices);
    const filteredHumidityAlarmDevices = filterDevicesWithValidacion(humidityAlarmDevices);
    const filteredtemperatureAlarmDevices = filterDevicesWithValidacion(temperatureAlarmDevices);

    const uniqueDevices = new Set([
      ...filteredCommunicationAlarmDevices,
      ...filteredSignalAlarmDevices,
      ...filteredBatteryAlarmDevices,
      ...filteredVoltageAlarmDevices,
      ...filteredHumidityAlarmDevices,
      ...filteredtemperatureAlarmDevices,
    ]);

    setTotalAlarmCount(uniqueDevices.size);
  }, [
    pendingCompanies,
    communicationAlarmDevices,
    signalAlarmDevices,
    batteryAlarmDevices,
    voltageAlarmDevices,
    humidityAlarmDevices,
    temperatureAlarmDevices,
  ]);

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,

        isUnRead: false,
      }))
    );
  };

  /// useEffect(() => {

  /// if (totalUnRead >= 3) {

  // playNotificationSound();

  // enqueueSnackbar('¡Tienes una notificación nueva!', {
  //  variant: 'info', // Puedes cambiar la variante según tu diseño
  //   anchorOrigin: {
  //   vertical: 'bottom',
  //   horizontal: 'right',
  //  },
  //   style: {
  //    color: 'white', // Establece el color del texto a blanco
  //  },
  //  });
  // }
  // }, [totalUnRead, enqueueSnackbar, playNotificationSound]);

  const handleCloseNotificationMessage = () => {
    setShowNotificationMessage(false);
  };

  return (
    <>
      <MIconButton ref={anchorRef} onClick={() => setOpen(true)} color={open ? 'primary' : 'default'}>
        <Badge badgeContent={totalUnRead} color="primary">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1, backgroundColor: '#fff' }}>
            <Typography variant="subtitle1">Notificaciones</Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <audio ref={notificationAudioRef} src={notificationSound} preload="auto">
          <track kind="captions" srcLang="en" label="Notifications Sound" />
        </audio>

        <OtherOrganizations
          pendingCompanies={pendingCompanies}
          setPendingCompanies={setPendingCompanies}
          totalAlarmCount={totalAlarmCount}
          setTotalAlarmCount={setTotalAlarmCount}
          devices={devices}
          setDevices={setDevices}
        />

        <Snackbar
          open={showNotificationMessage}
          autoHideDuration={5000}
          onClose={handleCloseNotificationMessage}
          message="¡Tienes una notificación nueva!"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          style={{ color: 'white' }}
        />
      </MenuPopover>
    </>
  );
}
