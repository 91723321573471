import jwtDecode from 'jwt-decode';
import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';
import firebase from 'firebase/app';
import axios from '../../utils/axios';
import { getUserByUid } from '../../_apis_/user';
import { setCurrentCompany, setCurrentBranch } from './company';
import { editUser, editUsersInCompany } from '../../services/UserService';

const initialState = {
  isLoading: false,
  error: false,
  userProfile: null,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null,
  isAuthenticated: false,
};
const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserProfile(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.userProfile = action.payload;
    },
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed,
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.myProfile = action.payload.user;
    },

    registerSuccess(state, action) {
      state.user = action.payload.user;
    },

    logoutSuccess(state) {
      state.isAuthenticated = false;
      localStorage.removeItem('userId');
      localStorage.removeItem('companyId');
      localStorage.removeItem('branchId');
      state.user = null;
    },
  },
});

export default slice.reducer;

export const { onToggleFollow, updateUserProfile, getProfileSuccess } = slice.actions;

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function login(user) {
  return async (dispatch) => {
    const JWT_SECRET = 'minimal-secret-key';
    const JWT_EXPIRES_IN = '5 days';
    const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN,
    });

    if (isValidToken(accessToken)) {
      setSession(accessToken);
    }
    localStorage.setItem('userId', user.uid);
    dispatch(slice.actions.updateUserProfile(user));
  };
}

// export function createUserChild(user) {
//   return async (dispatch) => {
//     await createUser(user);
//     await addUsersInUser('DlqDy6FbiyeHowWGU1g8z0dBQaC2', user);
//   };
// }

export function editUserChild(companyId, uid, user) {
  return async (dispatch) => {
    // const { currentUser } = firebase.auth();

    await editUser(uid, user);
    // await editUsersInUser(currentUser.uid, uid, user);
    await editUsersInCompany(companyId, uid, user);
  };
}

export function register(user) {
  return async (dispatch) => {
    const newUserRef = firebase.database().ref(`/users/${user.uid}`);
    newUserRef.set({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      photoURL: '/static/user/user.png',
      name: `${user.firstName} ${user.lastName}`,
    });

    localStorage.setItem('accessToken', user.email);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

export function logout() {
  return async (dispatch) => {
    localStorage.removeItem('userId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('branchSelected');
    localStorage.removeItem('branchId');
    localStorage.removeItem('redux-user');
    dispatch(setCurrentCompany(''));
    dispatch(setCurrentBranch(''));

    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const accessToken = window.localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');

    try {
      if (accessToken) {
        setSession(accessToken);
        const [fetchUser] = await Promise.all([getUserByUid(userId)]);

        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: fetchUser || {},
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null,
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null,
        })
      );
    }
  };
}

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};
