export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL,
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_REALTIME,
  authDomain: process.env.REACT_APP_FIREBASE_REALTIME_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_REALTIME_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_REALTIME_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_REALTIME_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_REALTIME_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_REALTIME_MEASUREMENT_ID,
};

export const emailJSConfig = {
  serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
  templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
  userId: process.env.REACT_APP_EMAILJS_USER_ID,
};

export const payPalConfig = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  secret: process.env.REACT_APP_PAYPAL_SECRET,
};

export const googleMapsConfig = {
  apiKey: process.env.REACT_APP_API_KEY_GOOGLE_MAPS,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};
