import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { useDispatch } from 'react-redux';
import { getUserByUid } from './user';
import { createLog } from '../redux/slices/log';

const usersBaseUrl = 'users';
const usersByCompanyBaseUrl = 'users_company';

export const getAllCompanies = async () => {
  // const { currentUser } = firebase.auth();
  const user = await firebase
    .database()
    .ref(`/companies`)
    .once('value')
    .then((snap) => snap.val());

  return user; 
};

export const getCompaniesByUser = async (user) => {
  const companies = [];
  const companyIds = Array.isArray(user.companies) ? user.companies : [];
  await Promise.all(
    companyIds.map(async (uid) => {
      await firebase
        .database()
        .ref(`${usersBaseUrl}/${uid}/companies`)
        .once('value')
        .then((snap) => {
          const snapData = snap.val();
          const company = {
            ...snapData,
            id: snap.key,
          };
          if (company.comercialName) {
            companies.push(company);
          }
        });
    })
  );
  return companies;
};

export const createNewCompany = async (company) => {
  const rootRef = firebase.database().ref('/');

  // const log = await createLog('company', 'create', company.id, [], company);

  // company = {
  //   ...company,
  //   log: [...(company.log || []), log],
  // };

  // const companies = rootRef.child('companies');
  // const newCompany = await companies.push(company);

  const usersRef = rootRef.child('users');
  const userOwnCompany = usersRef.child(company.rootUser);
  const user = await getUserByUid(company.rootUser);

  const userCompanies = user.companies || [];
  const updatedRootUser = {
    ...user,
    companies: [...userCompanies, { sam: { name: 'test company', role: 'admin', permissions: true } }],
  };
  userOwnCompany.update(updatedRootUser);
};

export const getCompanyById = async (id) => {
  let fetchedCompany = {};
  await firebase
    .database()
    .ref(`companies/${id}`)
    .once('value')
    .then((snap) => {
      const snapData = snap.val();
      const company = {
        ...snapData,
        id: snap.key,
      };
      fetchedCompany = company;
    });

  return fetchedCompany;
};

export const getCompanyById2 = async (id) => {
  let fetchedCompany = {};
  await firebase
    .database()
    .ref(`companies/${id}/photoURL`)
    .once('value')
    .then((snap) => {
      const snapData = snap.val();
      const company = {
        ...snapData,
        id: snap.key,
      };
      fetchedCompany = company;
    });

  return fetchedCompany;
};

// refactor function
export async function updateCompany(company) {
  const currentCompany = await getCompanyById(company.id);

  const log = await createLog('company', 'edit', company.id, currentCompany, company);

  const rootRef = firebase.database().ref('/');
  const companies = rootRef.child('companies');
  const currentCompanyRef = companies.child(company.id);
  const newCompany = {
    ...company,
    log: [...(company.log || []), log],
  };
  await currentCompanyRef.update(newCompany);
}

export const deleteCompany = async (company) => {
  const log = await createLog('company', 'delete', company.id, company, []);
  const rootRef = firebase.database().ref('/');
  const companies = rootRef.child('companies');
  const usersRef = rootRef.child('users');

  // Delete the company from the user owners
  console.log('company');
  console.log(company);
  const updatedUsers = await company.authorizedUsersIds.map(async (userId) => {
    const currentUser = await getUserByUid(userId);
    const currentUserRef = usersRef.child(userId);

    const nonDeletedCompanies = currentUser.companies?.filter((item) => item !== company.id);
    const updatedUser = {
      ...currentUser,
      companies: nonDeletedCompanies,
    };
    await currentUserRef.update(updatedUser);
  });
  await Promise.all(updatedUsers);

  // Delete company
  const currentCompanyRef = companies.child(company.id);
  currentCompanyRef.remove();
};
