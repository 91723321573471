import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  Card,

  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { MCircularProgress } from '../../@material-extend';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFoundinvi';


import ToolbarComponent from '../Toolbar/ToolbarComponent2';
import Iconify from '../../Iconify';

const InvitationTable = ({
  title,
  TABLE_HEAD,
  getDocuments,
  nameInSpanish,
  nameInEnglish,
  urlToDownloadSheet,
  isMasculine,
  needMassiveCharge,
  isLoading,
  documents,
  applySortFilter,
  isList,
  handleClickItem,
  tableStyle,
  dontNeedCheckBox,
  dontNeedCreateButton,
  anotherButton,
  anotherTextButton,
  handleAccept,
  handleDecline,
}) => {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('companyName');

  const filterDocuments = applySortFilter(documents, filterName);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - documents.length) : 0;
  const isProductNotFound = filterDocuments.length === 0;

  const rowStyle = {
    cursor: 'pointer',
    background: '#F4F6F8',
  };

  const handleClick = (event, name, id) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);

    setSelectedId(id);
  };



  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documents.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (getDocuments) getDocuments();
  }, [getDocuments]);

  const renderSwitchName = (row, el) => {
    if (el.isDate) {
      return moment(row[el.id]).fromNow();
    }
    if (el.isArray) {
      return row[el.isArray][el.id];
    }
    return row[el.id];
  };

  const onClickNormalItem = (row, id) => {
    if (handleClickItem) {
      handleClickItem(row);
      return;
    }
    history.push(`/dashboard/${nameInEnglish}/${id}/detail`);
  };

  // eslint-disable-next-line arrow-body-style
  const renderNormalItem = (row, el, id) => {
    return (
      <TableCell
        key={`${id}-${el.id}`}
        onClick={() => {
          onClickNormalItem(row, id);
        }}
        align="left"
      >
        {renderSwitchName(row, el)}
      </TableCell>
    );
  };

  // eslint-disable-next-line arrow-body-style
  const renderMoreMenu = (row, el, id) => {
    return (
      <TableCell key={`${id}-${el.id}-menu`} tabIndex={0} style={{ width: 120 }} align="left">
        <IconButton
          onClick={() => {
            handleAccept(row);
          }}
        >
          <Iconify icon={'el:ok-sign'} width={20} height={20} />
        </IconButton>
        <IconButton
          onClick={() => {
            handleDecline(row);
          }}
        >
          <Iconify icon={'el:remove-sign'} width={20} height={20} />
        </IconButton>
      </TableCell>
    );
  };

  return (
    <Card >
      {title && (
        <CardHeader
          title={
            <Grid>
    <Grid container justifyContent="center" marginTop="-100px"  >
  <Typography variant="h6" paddingTop={20}>{title}</Typography>
</Grid>
            </Grid>
          }
        />
      )}
      <ToolbarComponent
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        id={selected.length === 1 ? selectedId : null}
        setSelected={setSelected}
        name={nameInSpanish}
        isMasculine={isMasculine}
        nameInEnglish={nameInEnglish}
        needMassiveCharge={needMassiveCharge}
        urlToDownloadSheet={urlToDownloadSheet}
        dontNeedCreateButton={dontNeedCreateButton}
        anotherButton={anotherButton}
        anotherTextButton={anotherTextButton}
      />

      <Scrollbar>
        <TableContainer>
          <Table>
        

            <TableBody>
              {filterDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id } = row;
                const isItemSelected = selected.indexOf(id) !== -1;

                return (
                  <TableRow style={{ marginBottom: '-28px' }}>
                  {TABLE_HEAD.map((el) =>
                    el.id !== '' ? renderNormalItem(row, el, id) : renderMoreMenu(row, el, id)
                  )}
                </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={20} key={`empty`} />
                </TableRow>
              )}
            </TableBody>
            {isProductNotFound && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell key={`notFound`} align="center" colSpan={20}>
                    <Box sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell key={`isLoading`} align="center" colSpan={20}>
                    <Box sx={{ py: 3 }}>
                      <MCircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      
    </Card>
  );
};

// -----------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



InvitationTable.propTypes = {
  title: PropTypes.string,
  TABLE_HEAD: PropTypes.array,
  getDocuments: PropTypes.func,
  nameInSpanish: PropTypes.string,
  nameInEnglish: PropTypes.string,
  urlToDownloadSheet: PropTypes.string,
  isMasculine: PropTypes.bool,
  needMassiveCharge: PropTypes.bool,
  isLoading: PropTypes.bool,
  documents: PropTypes.array,

  isList: PropTypes.string,
  handleClickItem: PropTypes.func,
  tableStyle: PropTypes.object,

  dontNeedCreateButton: PropTypes.bool,
  anotherButton: PropTypes.func,
  anotherTextButton: PropTypes.string,
  handleAccept: PropTypes.func,
  handleDecline: PropTypes.func,
};

export default InvitationTable;