import firebase from 'firebase/app';
import 'firebase/database';
import { changeEmail } from '../utils/encryptText';
import { firebaseConfig } from '../config';
import { getPermissionsOfRoleByUid } from './RoleService';
import { createLog } from '../redux/slices/log';
// import { getCompany } from './CompanyService';

const usersBaseUrl = 'users';
const usersByCompanyBaseUrl = 'users_company';
const emialBaseUrl = 'emails_uid';




export async function getUsersByCompany(companyId) {
  const rootRef = firebase.database().ref(`/${usersByCompanyBaseUrl}/${companyId}`);
  return rootRef;
}

export const getAllUsers = async () => {
  // const { currentUser } = firebase.auth();
  const user = await firebase
    .database()
    .ref(`/${usersBaseUrl}`)
    .once('value')
    .then((snap) => snap.val());

  return user;
};

export const getUserByUid = async (uid) => {
  // const { currentUser } = firebase.auth();
  const user = await firebase
    .database()
    .ref(`${usersBaseUrl}/${uid}`)
    .once('value')
    .then((snap) => snap.val());

  return user;
};

 export const listenForChangesnotifications = async (branchID, id, callback) => {
   const myTable = firebase.database().ref(`/users/4uHTem0sYOWs6h6Qc5rfvVGMSKJ3/companies/-NYZOPxOR2DYScMDccpj/role`);
  myTable.on('value', async (snapshot) => {
    callback(snapshot.val());
   });
 };



export const getCompaniesByUser = async (uid) => {
  try {
    const user = await getUserByUid(uid);

    if (user && user.companies) {
      return Object.values(user.companies); // Devuelve la lista de compañías del usuario
    }

    return []; // Si el usuario no tiene compañías, devuelve un array vacío
  } catch (error) {
    console.error('Error al obtener las compañías del usuario:', error);
    throw error;
  }
};

export const createUser = async (company, roleId, user) => {
  const newUserRef = await firebase.database().ref(`/${usersBaseUrl}/${user.uid}`);
  // const permissions = await getPermissionsOfRoleByUid(company.id, roleId);
  // const company = await getCompany(companyId);

  const newUser = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    companies: {
      [company.id]: {
        name: company.name,
        role: 'pendiente',
        roleId,
        // permissions,
      },
    },
    photoURL: '/static/user/user.png',
  };

  await newUserRef.set({ ...newUser });
};

export const editUser = async (uid, user) => {
  // const permissions = await getPermissionsOfRoleByUid(user.roleId);
  const newUserRef = firebase.database().ref(`/${usersBaseUrl}/${uid}`);
  await newUserRef.update(user);
};

// export const addUsersInUser = async (user) => {
//   const { currentUser } = firebase.auth();
//   const rootRef = firebase.database().ref(`/user_users/${currentUser.uid}/${user.uid}`);
//   const newUser = { email: user.email, name: `${user.firstName} ${user.lastName}` };
//   await rootRef.set(newUser);
// };

export const addUsersInCompany = async (companyId, roleId, user, action) => {
  // const { currentUser } = firebase.auth();
  const rootRef = firebase.database().ref(`/${usersByCompanyBaseUrl}/${companyId}/${user.uid}`);

  const oldUserLog = [];
  const newUserLog = {
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
  };
  const key = `${user.uid}${companyId}`;
  const log = await createLog('user', action, key, oldUserLog, newUserLog);

  const newUser = {
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
    roleId,
    log: { ...user?.log, [log]: true },
  };
  await rootRef.set(newUser);
};

// export const editUsersInUser = async (userId, uid, user) => {
//   const rootRef = firebase.database().ref(`/user_users/${userId}/${uid}`);
//   const newUser = { email: user.email, name: `${user.firstName} ${user.lastName}` };
//   await rootRef.update(newUser);
// };

export const editUsersInCompany = async (companyId, uid, user) => {
  // const currentUser = await getUserByUid(uid);

  const key = `${uid}${companyId}`;
  const currentUserLog = { role: '' };
  const newUserLog = { role: user.companies[companyId].roleId };
  /* const log = */ await createLog('user', 'edit', key, currentUserLog, newUserLog);

  const rootRef = firebase.database().ref(`/${usersByCompanyBaseUrl}/${companyId}/${uid}`);
  const newUser = {
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
    roleId: user.companies[companyId].roleId,
    // log: { ...currentUser?.log, [log]: true },
  };
  await rootRef.update(newUser);
};

export const registerUser = async (user) => {
  const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
  const userAuth = await secondaryApp.auth().createUserWithEmailAndPassword(user.email, user.password);
  secondaryApp.auth().signOut();
  secondaryApp.delete();
  return userAuth.user;
};

// export const deleteUser = async (uid, userId) => {
//   const rootRef = firebase.database().ref('/');

//   // const userRef = rootRef.child(`users/${uid}/${userId}`);
//   // userRef.remove();

//   const userRef2 = rootRef.child(`user_users/${uid}/${userId}`);
//   userRef2.remove();
// };

export const deleteUserInCompany = async (companyId, userId, needLog, name, action) => {
  // const rootRef = firebase.database().ref('/');
  // const userRef = rootRef.child(`${usersByCompanyBaseUrl}/${companyId}/${userId}`);
  // await userRef.remove();
  if (needLog) {
    const key = `${userId}${companyId}`;
    const newLog = { banished: 'true', name };
    /* const log = */ await createLog('user', action, key, {}, newLog);
  }
  const updates = {};
  updates[`${usersByCompanyBaseUrl}/${companyId}/${userId}/banished`] = true;
  await firebase.database().ref().update(updates);
};

export function updateUser(uid, updatedData) {
  console.log('Updating user with UID:', uid);
  console.log('Updated Data:', updatedData);

  return firebase
    .database()
    .ref(`users/${uid}`)
    .update(updatedData)
    .then(() => {
      console.log(`User with ID ${uid} updated successfully.`);
    })
    .catch((error) => {
      console.error(`Error updating user with ID ${uid}:`, error);
      throw error;
    });
}
export const addEmailUid = async (user) => {
  const changedEmail = changeEmail(user.email);
  const rootRef = firebase.database().ref(`/${emialBaseUrl}/${changedEmail}`);
  const newUser = { uid: user.uid, name: `${user.firstName} ${user.lastName}` };
  await rootRef.set(newUser);
};

export const getUidByChangedEmail = async (changedEmail) => {
  const user = await firebase
    .database()
    .ref(`${emialBaseUrl}/${changedEmail}`)
    .once('value')
    .then((snap) => snap.val());
  return user?.uid;
};

export const getZonesByUser = async (uid) => {
  try {
    console.log('uid', uid);
    const user = await getUserByUid(uid); // Supongo que tienes una función getUserByUid para obtener los datos del usuario
    console.log('userrrr ', user);
    if (user && user?.zones) {
      return user.zones; // Devuelve las zonas del usuario si existen
    }
    console.log('hola:', user?.zones);
    return []; // Si el usuario no tiene zonas, devuelve un array vacío
  } catch (error) {
    console.error('Error userrrr al obtener las zonas del usuario:', error);
    throw error;
  }
};

export const addCompanyToUser = async (user, roleId, company) => {
  const newUserRef = firebase.database().ref(`/${usersBaseUrl}/${user.uid}`);
  // const company = await getCompany(companyId);
  const permissions = await getPermissionsOfRoleByUid(user.roleId);

  const newUser = {
    ...user,
    companies: {
      ...user.companies,
      [company.id]: {
        name: company.name,
        role: 'pendiente',
        roleId,
        permissions,
      },
    },
  };

  // await newUserRef.set({ ...newUser });
  await newUserRef.update({ ...newUser });
};

export const editStatusFromUser = async (user, roleId, company, newRole) => {
  const newUserRef = firebase.database().ref(`/${usersBaseUrl}/${user.uid}`);
  // const company = await getCompany(companyId);
  const permissions = await getPermissionsOfRoleByUid(user.roleId);

  const newUser = {
    ...user,
    companies: {
      ...user.companies,
      [company.id]: {
        name: company.name,
        role: newRole,
        roleId,
        permissions,
      },
    },
  };

  // await newUserRef.set({ ...newUser });
  await newUserRef.update({ ...newUser });
  return newUser;
};


export const listenForChangesofUser = async (branchID, id, callback,user) => {
  const myTable = firebase.database().ref(`/${usersBaseUrl}/${user.uid}`);
  myTable.on('value', async (snapshot) => {
    callback(snapshot.val());
  });
};