import PropTypes from 'prop-types';
import { visuallyHidden } from '@material-ui/utils';
import { Box, TableRow, TableCell, TableHead, TableSortLabel,Checkbox } from '@material-ui/core';

ProductListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  dontNeedCheckBox: PropTypes.bool,
};

export default function ProductListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  dontNeedCheckBox,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    
      <TableRow  >
        {!dontNeedCheckBox && (
       <TableCell padding="checkbox" sx={{ background: '#2196F3', color: 'black',borderRadius:'0px' }}>
       <Checkbox
       sx={{  color: 'black',borderRadius:'10px' }}
         indeterminate={numSelected > 0 && numSelected < rowCount}
         checked={numSelected === rowCount}
         onChange={onSelectAllClick}
       />
     </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ background: '#2196F3' , color: 'black',borderRadius:'0px'}} // Añadido para cambiar el color de fondo del TableCell
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
  
  );
}
