// material

import { Box , useTheme} from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ ...other }) {
  const theme = useTheme();
  return (
    <Box
    component="img"
    alt="logo"
    src={theme.palette.mode === 'dark' ? '/static/illustration/op.png' : '/static/brand/hola2.png'}
    height={70}
    marginTop={20}
    {...other}
  />
  );
}
