import React, { useState, useCallback,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  Card,

  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  CardHeader,
  Grid,
  Paper ,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime'; 
import { MCircularProgress } from '../../@material-extend';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFoundinvi';
import { getCompanyById} from '../../../_apis_/companies';
import {
  getDevicesByCompany,
  MultiApiDatagetDeviceById,
  getDevicesByZone,
  getDevicesByCompanyFilterByZone,
  getDevicesByCompanysql
  
  
} from '../../../_apis_/multiapiData';
import 'moment/locale/es';
import ToolbarComponent from '../Toolbar/ToolbarComponent2';
import Iconify from '../../Iconify';
// Importa el icono de reloj de Material-UI
const InvitationTable2 = ({
  title,
  TABLE_HEAD,
  getDocuments,
  nameInSpanish,
  nameInEnglish,
  urlToDownloadSheet,
  isMasculine,
  needMassiveCharge,

  documents,
  applySortFilter,
  isList,
  handleClickItem,
  tableStyle,
  dontNeedCheckBox,
  dontNeedCreateButton,
  anotherButton,
  anotherTextButton,
  handleAccept,
  handleDecline,
}) => {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [devices, setDevices] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { currentBranch, currentCompany } = useSelector((state) => state.company);
  const [orderBy, setOrderBy] = useState('companyName');
 const [devicesWithAlarm1, setDevicesWithAlarm1] = useState([]);
  const filterDocuments = applySortFilter(documents, filterName);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - documents.length) : 0;
  const isProductNotFound = filterDocuments.length === 0;

  const rowStyle = {
    cursor: 'pointer',
    background: '#F4F6F8',
  };



  const getInformation = useCallback(async () => {
    const company = await getCompanyById(currentCompany.id);
    const data = await getDevicesByCompany(company);
    const data2 = await getDevicesByCompanysql(company);
  
    // Combina las dos arrays
    const combinedData = data.concat(data2);
  
    const filter = combinedData.filter((device) => device.status === 'activo');
    const resulDevice = filter.map((el) => {
      const organizations = (el.companies || []).map((org) => org.companyName).join(', ');
      const zones = (el.zones || []).map((zone) => zone.nombre).join(', ');
      return {
        ...el,
        organizations,
        zones,
      };
    });

    setDevices(resulDevice);
    setIsLoading(false);
  }, [currentCompany]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  useEffect(() => {
    setFilteredDevices(devices);
  }, [devices]);
  const handleClick = (event, name, id) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);

    setSelectedId(id);
  };



  const voltageAlarmDevices = filteredDevices
  .filter((device) => device.status === 'activo')
  .filter((device) => {
   

    // Convertir voltaje a número para comparación
    const voltage = Number(device.batteryInfoVoltageInVolt);

    if ((device.marca === 'Keller' && voltage < 3.4) || (device.marca === 'HWM' && voltage < 6)) {
      // Alarma para Keller si el voltaje es menor a 3.4
      // Alarma para HWM si el voltaje es menor a 6
      device.voltageAlarm = true;
      return true;
    }

    device.voltageAlarm = false;
    return false;
  });

// Contar dispositivos con alarma por voltaje
const voltageAlarmCount = voltageAlarmDevices.length;

// Puedes usar voltageAlarmCount como desees en tu aplicación.


// Puedes usar voltageAlarmCount como desees en tu aplicación.


// Puedes usar voltageAlarmCount como desees en tu aplicación.


// Filtrar dispositivos y verificar la condición de alarma de batería
const batteryAlarmDevices = filteredDevices
  .filter((device) => device.status === 'activo' && device.marca === 'Keller')
  .filter((device) => {
    // Convertir el porcentaje de capacidad a número para comparación
    const batteryCapacityPercent = Number(device.batteryInfoCapacityInPercent);

    if (batteryCapacityPercent < 70) {
      // Alarma si el porcentaje de capacidad es menor a 70
      device.batteryAlarm = true;
      return true;
    }

    device.batteryAlarm = false;
    return false;
  });

// Contar dispositivos con alarma de batería
const batteryAlarmCount = batteryAlarmDevices.length;

// Puedes usar batteryAlarmCount como desees en tu aplicación.
// Filtrar dispositivos y verificar la condición de alarma por señal
const signalAlarmDevices = filteredDevices
  .filter((device) => device.status === 'activo')
  .filter((device) => {
    // Convertir la señal a número para comparación
    const signalQuality = Number(device.signalQuality);

    if (signalQuality < 10) {
      // Alarma si la señal es menor a 10
      device.signalAlarm = true;
      return true;
    }

    device.signalAlarm = false;
    return false;
  });

// Contar dispositivos con alarma por señal
const signalAlarmCount = signalAlarmDevices.length;

// Puedes usar signalAlarmCount como desees en tu aplicación.


  const comunicacionValues = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => Number(row.comunicacionValue));

    const humidityValues = filteredDevices
    .filter((device) => device.status === 'activo' && device.marca === 'Keller')
    .map((row) => {
      const humidityValue = Number(row.humidity);
  
      // Añadir lógica de alarma para la humedad
      if (humidityValue > 90) {
        // Puedes hacer algo aquí para indicar la alarma, por ejemplo, cambiar el color
        // de la barra en el gráfico.
        row.humidityAlarm = true; // Añadir una propiedad humidityAlarm para contar después.
      } else {
        row.humidityAlarm = false;
      }
  
      return humidityValue;
    });

    const humidityAlarmDevices = filteredDevices
  .filter((device) => device.status === 'activo' && device.marca === 'Keller')
  .filter((device) => {
    // Convertir la humedad a número para comparación
    const humidityValue = Number(device.humidity);

    if (humidityValue > 90) {
      // Alarma si la humedad es mayor a 90
      device.humidityAlarm = true; // Añadir una propiedad humidityAlarm para contar después.
      return true;
    }

    device.humidityAlarm = false;
    return false;
  });
  
  // Contar dispositivos con alarma de humedad
  const humidityAlarmDeviceNames = humidityAlarmDevices.map((device) => device.name);
  const humidityAlarmDeviceNamesti = humidityAlarmDevices.map((device) => device.lastMeasurementTransmissionDateTimeUtc);
  // Contar dispositivos con alarma de humedad
  const humidityAlarmDeviceNames1 = humidityAlarmDevices.map((device) => device);
  const humidityAlarmCount = humidityAlarmDevices.length;

  

  const sensorValues = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => Number(row.sensorValue));

  const temperaturaValues = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => Number(row.temperaturaValue));

    const señalValues = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => {
      const signalValue = Number(row.signalQuality);
  
      // Añadir lógica de alarma
      if (signalValue < 10) {
        // Puedes hacer algo aquí para indicar la alarma, por ejemplo, cambiar el color
        // de la barra en el gráfico.
        row.hasAlarm = true; // Añadir una propiedad hasAlarm para contar después.
      } else {
        row.hasAlarm = false;
      }
  
      return signalValue;
    });
  
  // Contar dispositivos con alarma
  const devicesWithAlarm = señalValues.filter((value) => value < 10);
  const alarmCount = devicesWithAlarm.length;

  const lastMeasurementTransmissionDateTimeUtc = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => row.lastMeasurementTransmissionDateTimeUtc);


    const currentTime = new Date();

// Definir el límite de tiempo para la alarma (24 horas en milisegundos)
const alarmTimeLimit = 24 * 60 * 60 * 1000; // 24 horas en milisegundos

// Filtrar dispositivos y verificar la condición de alarma de comunicación
const communicationAlarmDevices = filteredDevices
  .filter((device) => device.status === 'activo')
  .filter((device) => {
    const lastTransmissionDateTime = new Date(device.lastMeasurementTransmissionDateTimeUtc);

    // Calcular la diferencia de tiempo entre la última transmisión y la hora actual
    const timeDifference = currentTime - lastTransmissionDateTime;

    // Verificar si la diferencia de tiempo supera el límite establecido
    if (timeDifference > alarmTimeLimit) {
      // Puedes hacer algo aquí para indicar la alarma, por ejemplo, cambiar el color
      // de la barra en el gráfico.
      device.communicationAlarm = true; // Añadir una propiedad communicationAlarm para contar después.
      return true; // Este dispositivo cumple con la condición de alarma.
    // eslint-disable-next-line no-else-return
    } else {
      device.communicationAlarm = false;
      return false; // Este dispositivo no cumple con la condición de alarma.
    }
  });

// Contar dispositivos con alarma de comunicación
const communicationAlarmCount = communicationAlarmDevices.length;
  const voltageAlarmDeviceNames = voltageAlarmDevices.map((device) => device.name);
  const voltageAlarmDeviceNamesti = voltageAlarmDevices.map((device) => device.lastMeasurementTransmissionDateTimeUtc);
  const voltageAlarmDeviceNames1 = voltageAlarmDevices.map((device) => device);
// Obtener nombres de dispositivos con alarma de batería
const batteryAlarmDeviceNames = batteryAlarmDevices.map((device) => device.name);
const batteryAlarmDeviceNamesti = batteryAlarmDevices.map((device) => device.lastMeasurementTransmissionDateTimeUtc);
const batteryAlarmDeviceNames1 = batteryAlarmDevices.map((device) => device);
const signalAlarmDeviceNames = signalAlarmDevices.map((device) => device.name);
const signalAlarmDeviceNamesti = signalAlarmDevices.map((device) => device.lastMeasurementTransmissionDateTimeUtc);
// Obtener nombres de dispositivos con alarma de comunicación
const signalAlarmDeviceNames1 = signalAlarmDevices.map((device) => device);


const communicationAlarmDeviceNames = communicationAlarmDevices.map((device) => device.name);
const communicationAlarmDeviceNamesti = communicationAlarmDevices.map((device) => device.lastMeasurementTransmissionDateTimeUtc);

const communicationAlarmDeviceNames1 = communicationAlarmDevices.map((device) => device);






const combinedArray = communicationAlarmDeviceNames1
  .concat(signalAlarmDeviceNames1, batteryAlarmDeviceNames1, voltageAlarmDeviceNames1, humidityAlarmDeviceNames1);

// Alternativamente, puedes utilizar el operador spread (...) para lograr lo mismo
// const combinedArray = [...communicationAlarmDeviceNames1, ...signalAlarmDeviceNames1, ...batteryAlarmDeviceNames1, ...voltageAlarmDeviceNames1, ...humidityAlarmDeviceNames1];




useEffect(() => {
  // Actualiza el estado con el array combinado
  setDevicesWithAlarm1(combinedArray);
}, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documents.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  

  const onClickNormalItem = (row, id) => {
    if (handleClickItem) {
      handleClickItem(row);
      return;
    }
    history.push(`/dashboard/${nameInEnglish}/${id}/detail`);
  };

  // eslint-disable-next-line arrow-body-style


  // eslint-disable-next-line arrow-body-style
  
  return (
    <Card sx={tableStyle}>
      {title && (
        <CardHeader
          title={
            <Grid>
    <Grid container justifyContent="center" marginTop="-100px"  >
  <Typography variant="h6" paddingTop={20}>{title}</Typography>
</Grid>
            </Grid>
          }
        />
      )}
      <ToolbarComponent
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        id={selected.length === 1 ? selectedId : null}
        setSelected={setSelected}
        name={nameInSpanish}
        isMasculine={isMasculine}
        nameInEnglish={nameInEnglish}
        needMassiveCharge={needMassiveCharge}
        urlToDownloadSheet={urlToDownloadSheet}
        dontNeedCreateButton={dontNeedCreateButton}
        anotherButton={anotherButton}
        anotherTextButton={anotherTextButton}
      />

      <Scrollbar>
      <TableContainer component={Paper} style={{ maxHeight: '300px', overflow: 'auto', marginTop: '-10px' }}>
      <Table>
  <TableBody>
    {devicesWithAlarm1.map((row) => {
        const { id, name, lastMeasurementTransmissionDateTimeUtc } = row;
      const isItemSelected = selected.indexOf(id) !== -1;
      if (name && lastMeasurementTransmissionDateTimeUtc) {
        const isItemSelected = selected.indexOf(id) !== -1;
      
      return (
        <TableRow style={{ marginBottom: '-28px' }} key={id}>
          {/* Celda para mostrar los nombres de dispositivos con alarmas */}
          <TableCell style={{ width: '100%', textAlign: 'center' }}>

            
          <div style={{ margin: '10px', padding: '10px', border: '1px solid #000', borderRadius: '10px' }}>
          
            {batteryAlarmDeviceNames.includes(row.name) && `Alarma de Batería (${row.name})`}
            {batteryAlarmDeviceNamesti.map(dateString => {
  const dateFromList = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS');
  const dateFromRow = moment(row.lastMeasurementTransmissionDateTimeUtc);
  const formattedDate = dateFromList.format('dddd DD h:mm A YYYY');
  dateFromList.locale('es');
  dateFromRow.locale('es');
  if (dateFromList.isSame(dateFromRow)) {
    return (
      <div key={dateString}>
          <div>
          <AccessTimeIcon fontSize="12" style={{ marginRight: '-2px',marginTop:'13px' }} /> {/* Icono de reloj más pequeño */}
        <span>Fecha:</span>
      </div>
        <div>{formattedDate}</div>
      </div>
    );
  }

  return null;
})}


{voltageAlarmDeviceNames.includes(row.name) && `Alarma de Batería (${row.name})`}
            {voltageAlarmDeviceNamesti.map(dateString => {
  const dateFromList = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS');
  const dateFromRow = moment(row.lastMeasurementTransmissionDateTimeUtc);
  const formattedDate = dateFromList.format('dddd DD h:mm A YYYY');
  dateFromList.locale('es');
  dateFromRow.locale('es');
  if (dateFromList.isSame(dateFromRow)) {
    return (
      <div key={dateString}>
          <div>
          <AccessTimeIcon fontSize="12" style={{ marginRight: '-2px',marginTop:'13px' }} /> {/* Icono de reloj más pequeño */}
        <span>Fecha:</span>
      </div>
        <div>{formattedDate}</div>
      </div>
    );
  }

  return null;
})}

            {signalAlarmDeviceNames.includes(row.name) && `Alarma de Señal (${row.name})`}
            {signalAlarmDeviceNamesti.map(dateString => {
  const dateFromList = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS');
  const dateFromRow = moment(row.lastMeasurementTransmissionDateTimeUtc);
  const formattedDate = dateFromList.format('dddd DD h:mm A YYYY');
  dateFromList.locale('es');
  dateFromRow.locale('es');
  if (dateFromList.isSame(dateFromRow)) {
    return (
      <div key={dateString}>
          <div>
          <AccessTimeIcon fontSize="12" style={{ marginRight: '-2px',marginTop:'13px' }} /> {/* Icono de reloj más pequeño */}
        <span>Fecha:</span>
      </div>
        <div>{formattedDate}</div>
      </div>
    );
  }

  return null;
})}
            {communicationAlarmDeviceNames.includes(row.name) && `Alarma de Comunicación (${row.name})`}
            {communicationAlarmDeviceNamesti.map(dateString => {
  const dateFromList = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS');
  const dateFromRow = moment(row.lastMeasurementTransmissionDateTimeUtc);
  dateFromList.locale('es');
  dateFromRow.locale('es');
  const formattedDate = dateFromList.format('dddd DD h:mm A YYYY');
  if (dateFromList.isSame(dateFromRow)) {
    return (
      <div key={dateString}>
          <div>
          <AccessTimeIcon fontSize="12" style={{ marginRight: '-2px',marginTop:'13px' }} /> {/* Icono de reloj más pequeño */}
        <span>Fecha:</span>
      </div>
        <div>{formattedDate}</div>
      </div>
    );
  }

  return null;
})}



            {humidityAlarmDeviceNames.includes(row.name) && `Alarma de Humedad (${row.name})`}
            {humidityAlarmDeviceNamesti.map(dateString => {
  const dateFromList = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS');
  const dateFromRow = moment(row.lastMeasurementTransmissionDateTimeUtc);
  dateFromList.locale('es');
  dateFromRow.locale('es');
  const formattedDate = dateFromList.format('dddd DD h:mm A YYYY');
  if (dateFromList.isSame(dateFromRow)) {
    return (
      <div key={dateString}>
          <div>
          <AccessTimeIcon fontSize="12" style={{ marginRight: '-2px',marginTop:'13px' }} /> {/* Icono de reloj más pequeño */}
        <span>Fecha:</span>
      </div>
        <div>{formattedDate}</div>
      </div>
    );
  }

  return null;
})}
              </div>
          </TableCell>

        </TableRow>
      );
    }

    return null;
    })}
    
  </TableBody>
</Table>

</TableContainer>

      </Scrollbar>

      
    </Card>
  );
};
console.log('nuem2')
// -----------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



InvitationTable2.propTypes = {
  title: PropTypes.string,
  TABLE_HEAD: PropTypes.array,
  getDocuments: PropTypes.func,
  nameInSpanish: PropTypes.string,
  nameInEnglish: PropTypes.string,
  urlToDownloadSheet: PropTypes.string,
  isMasculine: PropTypes.bool,
  needMassiveCharge: PropTypes.bool,
  isLoading: PropTypes.bool,
  documents: PropTypes.array,

  isList: PropTypes.string,
  handleClickItem: PropTypes.func,
  tableStyle: PropTypes.object,

  dontNeedCreateButton: PropTypes.bool,
  anotherButton: PropTypes.func,
  anotherTextButton: PropTypes.string,
  handleAccept: PropTypes.func,
  handleDecline: PropTypes.func,
};

export default InvitationTable2;