/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Icon } from '@iconify/react';
import Collapse from '@material-ui/core/Collapse';
import { useSelector } from 'react-redux';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { experimentalStyled as styled, alpha } from '@material-ui/core/styles';
import {
  Box,
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener
} from '@material-ui/core';
// components
import { MIconButton } from '../../components/@material-extend';
import {
  getDevicesByCompany,
  MultiApiDatagetDeviceById,
  getDevicesByZone,
  getDevicesByCompanyFilterByZone,
  getDevicesByCompanysql
  
} from '../../_apis_/multiapiData';
import { getCompanyById} from '../../_apis_/companies';
import './search.css';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 10,

  width: '100%',
  display: 'flex',
marginLeft:'20px',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: '0px 0px 10px 3px #21A0F2',
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));



// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const { currentBranch, currentCompany } = useSelector((state) => state.company);
  const [searchTerm, setSearchTerm] = useState('');


  const getInformation = useCallback(async () => {
    const company = await getCompanyById(currentCompany.id);
    const data = await getDevicesByCompany(company);
    const data2 = await getDevicesByCompanysql(company);
  
    // Combina las dos arrays
    const combinedData = data.concat(data2);
  
    const filter = combinedData.filter((device) => device.status === 'activo');
    const resulDevice = filter.map((el) => {
      const organizations = (el.companies || []).map((org) => org.companyName).join(', ');
  
      // Verifica que el.zones sea un array
      const zonesArray = Array.isArray(el.zones) ? el.zones : [];
      const zones = zonesArray.map((zone) => zone.nombre).join(', ');
  
      return {
        ...el,
        organizations,
        zones,
      };
    });
  
    setDevices(resulDevice);
    setIsLoading(false);
  }, [currentCompany, searchTerm]);
  

  useEffect(() => {
    getInformation();
  }, [getInformation, searchTerm]);



  const handlesearch = (pathId) => {

    console.log('pathId', pathId);
    window.location.href = `/dashboard/listview/${pathId}`;
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

<Collapse in={isOpen} mountOnEnter unmountOnExit>
<SearchbarStyle>
  <Input
    autoFocus
    fullWidth
    disableUnderline
    placeholder="Buscar dispositivo…"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    startAdornment={
      <InputAdornment position="start">
        <Box
          component={Icon}
          icon={searchFill}
          sx={{ color: 'text.disabled', width: 20, height: 20 }}
        />
      </InputAdornment>
    }
    sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
  />


  {/* Lista de dispositivos filtrada por nombre */}
  {isOpen && searchTerm && devices && (
   <div className="search-results-container" style={{maxHeight: '200px',  overflowY: 'auto' ,width:'100%', backgroundColor:'#fff' , zIndex: 1000000000, marginTop: '90px' }}>
      {devices.filter((device) => device && device.name && device.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((device) => (
          // Renderizar la información del dispositivo aquí
          // Por ejemplo, puedes mostrar el nombre del dispositivo:
          // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/mouse-events-have-key-events
          <div key={device.id}  role="button" style={{ color: 'black', marginTop: '-1px', zIndex: 100000000 , marginLeft: '2px' }} onClick={() => handlesearch(device.name)}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = '#3BBBFA'; // Cambia el color de fondo al pasar el mouse
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = 'transparent'; // Restaura el color de fondo cuando el mouse sale
          }}
        >
          {device.name}
        </div>
        ))}
    </div>
  )}
</SearchbarStyle>

</Collapse>

      </div>
    </ClickAwayListener>
  );
}
