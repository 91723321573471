import { Box, Button, Drawer, Hidden, List, ListSubheader, Typography } from '@material-ui/core';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import 'firebase/database';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, matchPath, useHistory, useLocation } from 'react-router-dom';
import validateCompanySubscribed from '../../utils/validatePayments';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
import MenuLinks from './SidebarConfig';
import SidebarItem from './SidebarItem';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 0.08) : theme.palette.primary.lighter,
}));

function reduceChild({ array, item, pathname, level }) {
  const key = `${item.href}-${level}`;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items,
        })}
      </SidebarItem>,
    ];
  }
  return [
    ...array,
    <SidebarItem key={key} level={level} href={item.href} icon={item.icon} info={item.info} title={item.title} />,
  ];
}

function renderSidebarItems({ items, pathname, level = 0 }) {
  return <List disablePadding>{items.reduce((array, item) => reduceChild({ array, item, pathname, level }), [])}</List>;
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { myProfile } = useSelector((state) => state.user);
  const { currentCompany } = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const [isOwner, setIsOwner] = useState(false);
  const [isCompanySubscribed, setIsCompanySubscribed] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);

  const redirectPaymentPage = () => {
    history.push(`/dashboard/payment`);
  };

  const redirectDashboardPage = () => {
    history.push(`/dashboard/general`);
  };

  const validateGuestPath = () => {
    const isOwner = myProfile?.companies ? myProfile?.companies[currentCompany?.id]?.role === 'owner' || false : false;
    return !isOwner && String(pathname).includes(PATH_DASHBOARD.payment.root);
  };

  const isValidPath = (pathname) =>
    pathname === PATH_DASHBOARD.general.general ||
    pathname === PATH_DASHBOARD.general.organization.root ||
    pathname === PATH_DASHBOARD.general.organization.create ||
    String(pathname).includes(PATH_DASHBOARD.payment.root);

  useEffect(() => {
    setIsOwner(myProfile?.companies?.[currentCompany?.id]?.role === 'owner');
  }, [myProfile, currentCompany]);

  useEffect(() => {
    const fetchValidation = async () => {
      const isCompanySubscribedTemp = await validateCompanySubscribed(
        currentCompany.subscription,
        currentCompany,
        myProfile
      );

      setIsCompanySubscribed(isCompanySubscribedTemp);
      setShowSidebar(currentCompany.id && isCompanySubscribedTemp);
    };

    fetchValidation();
  }, [currentCompany, myProfile]);

  const validateSuperAdmin = (list, subheader) => {
    const validation = myProfile?.isSuperAdmin;
    const hasCurrentCompany = !!currentCompany; // Verificar si currentCompany existe
  
    // Verificar si currentCompany existe y el usuario es un superadministrador
    if (hasCurrentCompany && validation) {
      return list.subheader === subheader;
    }
  
    return false; // No mostrar la sección "Setup" si no se cumple la condición
  };
  

  const validateSuperAdmin2 = (list, subheader) => {
    const validation = myProfile?.isSuperAdmin;
    const hasCurrentCompany = !!currentCompany; // Verificar si currentCompany existe
  
    // Verificar si currentCompany existe y el usuario es un superadministrador
    if (validation) {
      return list.subheader === 'dashboard';
    }
  
    return false; // No mostrar la sección "Setup" si no se cumple la condición
  };

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }

    if (validateGuestPath()) {
      redirectDashboardPage();
    }

    if (!isCompanySubscribed && !isValidPath(pathname)) {
      redirectPaymentPage();
    }
  }, [pathname, isCompanySubscribed]);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>

      {MenuLinks.map((list, index) => {
             if (validateSuperAdmin2(list, 'dashboard')) {
          return (
            <List
              disablePadding
              key={`item-${index}`}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  sx={{
                    mt: 3,
                    mb: 2,
                    pl: 5,
                    color: (theme) => theme.palette.error,
                    typography: 'overline',
                  }}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderSidebarItems({
                items: list.items,
                pathname,
              })}
            </List>
          );
        }

        if (validateSuperAdmin(list, 'Setup')) {
          return (
            <List
              disablePadding
              key={`item-${index}`}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  sx={{
                    mt: 3,
                    mb: 2,
                    pl: 5,
                    color: (theme) => theme.palette.error,
                    typography: 'overline',
                  }}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderSidebarItems({
                items: list.items,
                pathname,
              })}
            </List>
          );
        }

        // Handle other subheaders if needed
        // ...

        return null; // Return null if you don't want to render anything for other cases
      })}

      {MenuLinks.map(
        (list, index) =>
          (list.subheader === 'administracion' ||
            list.subheader === 'nodos' ||
            list.subheader === 'dispositivos' ||
            list.subheader === 'envio' ||
            list.subheader === 'bridges' ||
            list.subheader === 'Vistas') &&
          // (list.subheader === 'Setup' && isOwner))
          // validateSuperAdmin(list, 'Setup'))
          showSidebar && (
            <List
              disablePadding
              key={`item-${index}`}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  sx={{
                    mt: 3,
                    mb: 2,
                    pl: 5,
                    color: (theme) => theme.palette.error,
                    typography: 'overline',
                  }}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderSidebarItems({
                items: list.items,
                pathname,
              })}
            </List>
          )
      )}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>

      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' },
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
